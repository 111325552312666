import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Banner from './components/Banner';
import { MoonLoader } from 'react-spinners';
import './App.css'
import Footer from './components/Footer';

const HomePage = lazy(() => import('./pages/HomePage'));
const EpisodesPage = lazy(() => import('./pages/EpisodesPage'));
const Hero = lazy(() => import('./pages/Hero'));
const Story = lazy(() => import('./pages/Story'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const VideoPage = lazy(() => import('./pages/VideoPage'))

function App() {
    return (
        <Router>
            <div className="App">
                <Banner />
                <Header />
                <Suspense fallback={<div className="spinner-container"><MoonLoader /></div>}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/hero" element={<Hero />} />
                        <Route path="/story" element={<Story />} />
                        <Route path="/1-season" element={<EpisodesPage />} />
                        {/*<Route path="/:season/:episode" element={<EpisodeDetailPage />} /> */}
                        <Route path="/:season/:episode" element={<VideoPage />} />

                        {/* Маршрут для несуществующих страниц */}
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes></Suspense>
                <Footer />
            </div>
        </Router>
    );
}

export default App;

