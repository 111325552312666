import React from "react";
import { MdEmail } from "react-icons/md";
import "./Footer.css";

const Footer = () => {
    const navigationLinks = [
        { name: "Главная", url: "/" },
    ];

    return (
        <footer className="footer">
            <div className="container">
                <div className="footer-grid">
                    {/* About Us Section */}
                    <div className="about-us">
                        <h3>Правообладателям</h3>
                        <p>
                            Материалы на сайте представлены только для ознакомления. Если Вы являетесь правообладателем и какой-либо материал нарушает Ваши авторские права, обратитесь к нам через форму обратной связи.

                        </p>
                    </div>

                    {/* Quick Links Section */}
                    <div className="quick-links">
                        <h3>Быстрый доступ</h3>
                        <ul>
                            {navigationLinks.map((link) => (
                                <li key={link.name}>
                                    <a href={link.url}>{link.name}</a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Contact Information */}
                    <div className="contact-us">
                        <h3>Контакты</h3>
                        <div className="contact-item">
                            <MdEmail className="icon" />
                            <a href="mailto:info@lumon.fun">info@lumon.fun</a>
                        </div>
                    </div>
                </div>

                {/* Copyright Section */}
                <div className="footer-bottom">
                    <p>Все серии "Разделение" онлайн 2022-2025</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
