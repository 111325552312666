// config.js

const config = {
    title: "Игра в кальмара",
    name: "ИГРА В КАЛЬМАРА",
    titleEng: "squidgame",
    slogan: "Выживание — это искусство.",
    baseUrl: "https://squidgame.fun/",
    genre: ["триллер", "драма", "выживание", "боевик"],
    country: "Южная Корея",
    releaseYear: 2021,
    directors: ["Хван Дон-хёк"],
    cast: [
        {
            name: "Ли Джон-джэ",
            hero: "Сон Ги-хун",
            src: "/images/actors/LeeJeong-jae.webp"
        },
        {
            name: "Пак Хэ-су",
            hero: "Чо Сан-ву",
            src: "/images/actors/ParkHae-soo.webp"
        },
        {
            name: "Ви Ха-джун",
            hero: "Хван Джун-хо",
            src: "/images/actors/WiHa-joon.webp"
        },
        {
            name: "Чон Хо-ён",
            hero: "Кан Саэ-бёк",
            src: "/images/actors/JeongHo-yeon.webp"
        },
        {
            name: "О Ён-су",
            hero: "О Ил-нам",
            src: "/images/actors/YeongSu-oh.webp"
        },
        {
            name: "Хо Сон-тхэ",
            hero: "Чан Док-су",
            src: "/images/actors/HeoSung-tae.webp"
        },
        {
            name: "Ким Джу-рён",
            hero: "Хан Ми-нё",
            src: "/images/actors/KimJoo-ryeong.webp"
        }
    ],
    ratings: {
        imdb: {
            rating: 8.0,
            webLink: "https://www.imdb.com/title/tt10919420/"
        },
        rottenTomatoes: {
            rating: 95,
            webLink: "https://www.rottentomatoes.com/tv/squid_game"
        },
        kinopoisk: {
            rating: 7.6,
            webLink: "https://www.kinopoisk.ru/series/1301710/"
        },
        metaCritic: {
            rating: 69,
            webLink: "https://www.metacritic.com/tv/squid-game/"
        }
    },
    description:
        "«Игра в кальмара» — это захватывающий южнокорейский сериал, в котором сотни людей, столкнувшихся с финансовыми трудностями, соглашаются на участие в таинственном соревновании. Их ожидают детские игры с высокими ставками, где цена поражения — смерть. Победитель получит крупную сумму денег, но сможет ли он сохранить человечность, преодолевая ужасы?",
    seasons: [
        {
            seasonNumber: 1,
            episodes: [
                {
                    title: "Красный свет, зелёный свет",
                    id: 1,
                    image: "/images/episodes/season1/episode1Season1PreviewImg.webp",
                    description: "Участники впервые сталкиваются с правилами смертельных игр.",
                    long_description: "Сон Ги Хун, человек с финансовыми проблемами, соглашается участвовать в загадочном соревновании. Его вместе с сотнями других участников привозят на остров, где они сталкиваются с первой игрой — 'Красный свет, зелёный свет'. В ходе игры становится ясно, что поражение приводит к смертельному исходу. Пережив шок, Ги Хун понимает, что пути назад нет."
                },
                {
                    title: "Ад",
                    id: 2,
                    image: "/images/episodes/season1/episode2Season1PreviewImg.webp",
                    description: "Участники начинают осознавать истинную природу соревнования.",
                    long_description: "После первой игры участники оказываются в состоянии шока. Им предоставляется возможность покинуть соревнование, но многие возвращаются, осознавая, что игра — их последний шанс на спасение. Вторая игра 'Далгона' требует от участников выдержки и точности."
                },
                {
                    title: "Человек с зонтиком",
                    id: 3,
                    image: "/images/episodes/season1/episode3Season1PreviewImg.webp",
                    description: "Участники сталкиваются с игрой, где важна внимательность и терпение.",
                    long_description: "Следующая игра — задача вырезать фигуру из сахара, не повредив её. Для некоторых участников это становится непреодолимой задачей, что приводит к трагическим последствиям. Ги Хун с трудом справляется с испытанием, используя находчивость."
                },
                {
                    title: "Союзы",
                    id: 4,
                    image: "/images/episodes/season1/episode4Season1PreviewImg.webp",
                    description: "Участники формируют альянсы, чтобы выжить.",
                    long_description: "Игроки понимают, что выживание требует не только удачи, но и стратегического мышления. Формируются альянсы, чтобы повысить шансы на успех. Между тем организаторы игры всё больше раскрывают свои безжалостные методы."
                },
                {
                    title: "Гамбит",
                    id: 5,
                    image: "/images/episodes/season1/episode5Season1PreviewImg.webp",
                    description: "Напряжение между участниками растёт, игра становится всё жестче.",
                    long_description: "Участники сталкиваются с новой игрой, в которой им нужно работать в парах. Выбор партнёра становится решающим моментом, ведь каждый понимает, что это может быть вопросом жизни и смерти."
                },
                {
                    title: "Мраморные судьбы",
                    id: 6,
                    image: "/images/episodes/season1/episode6Season1PreviewImg.webp",
                    description: "Эмоциональная игра ставит дружбу на грань.",
                    long_description: "Игроки вынуждены играть в традиционные корейские игры на мраморных шариках, ставя на кон свои жизни. Старый мужчина О Ил Нам вызывает у Ги Хуна моральные сомнения, а Кан Саэ Бёк демонстрирует неожиданные качества."
                },
                {
                    title: "Мост стеклянных шагов",
                    id: 7,
                    image: "/images/episodes/season1/episode7Season1PreviewImg.webp",
                    description: "Каждое решение может стать роковым.",
                    long_description: "Участники должны пересечь мост из стеклянных панелей, где некоторые панели ненадёжны. Игра требует не только везения, но и расчётливости. Атмосфера становится всё более напряжённой."
                },
                {
                    title: "Финальная игра",
                    id: 8,
                    image: "/images/episodes/season1/episode8Season1PreviewImg.webp",
                    description: "Противостояние достигает пика.",
                    long_description: "Ги Хун и Чо Сан У сталкиваются лицом к лицу в финальной игре. Эмоции, предательства и борьба за выживание выходят на новый уровень. Победитель узнаёт всю правду о соревновании."
                },
                {
                    title: "Последствия",
                    id: 9,
                    image: "/images/episodes/season1/episode9Season1PreviewImg.webp",
                    description: "Победитель возвращается в реальный мир, но его жизнь уже никогда не будет прежней.",
                    long_description: "Ги Хун пытается справиться с посттравматическим стрессом и вернуться к нормальной жизни. Однако правда об игре в кальмара продолжает преследовать его, и он принимает важное решение."
                },
            ]
        },
        {
            seasonNumber: 2,
            episodes: [
                {
                    title: "Трейлер",
                    id: 1,
                    image: "/images/episodes/season2/episode1Season2PreviewImg.webp",
                    description: "",
                    long_description: ""
                },
            ],
        },
    ],

    // Функция для генерации списка субтитров
    getSubtitles(season, episode) {
        return [
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/eng.vtt`,
                label: 'English'
            },
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/ko_force.vtt`,
                label: 'Korean Forced'
            },
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/ko.vtt`,
                label: 'Korean'
            },
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/rus_force.vtt`,
                label: 'Russian Forced',
                default: true
            },
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/rus.vtt`,
                label: 'Russian'
            },
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/ukr_force.vtt`,
                label: 'Ukrainian Forced'
            },
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/ukr.vtt`,
                label: 'Ukrainian'
            },
            {
                kind: 'subtitles',
                src: `${this.baseUrl}api/videos/${this.titleEng}/${season}/${episode}/sdh.vtt`,
                label: 'SDH'
            }
        ];
    }
};

export default config;
