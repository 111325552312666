import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import './Header.css';
import config from '../config';

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header className="header">
            <div className="logo">
                <h1>
                    <Link to="/">
                        {config.name}
                    </Link>
                </h1>
            </div>
            <nav className={`nav ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                    <li><Link to="/story">СЮЖЕТ</Link></li>
                    <li><Link to="/hero">ПЕРСОНАЖИ</Link></li>
                    <li><Link to="/1-season">1 СЕЗОН</Link></li>
                </ul>
            </nav>
            <div className="burger" onClick={toggleMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>
        </header>
    );
};

export default Header;