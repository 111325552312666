import React from 'react';
import './Banner.css';

const Banner = () => {
    return (
        <section className="banner">
            <img src="/images/posters/posterLong.jpg" alt="Severance advertising poster" />
        </section>
    );
};

export default Banner;